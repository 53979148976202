(function($) {

  var jumbotronAnimation = function(){
    var windowPosition = $(window).scrollTop();
    var newTitleYCoord = windowPosition / 2.5;
    var newImgYCoord = windowPosition / 8 * -1;
    $('.home-jumbotron .jumbotron-title').css('transform', 'translateY('+newTitleYCoord+'px)');
    $('.home-jumbotron .jumbotron-img-2').css('transform', 'translateY('+newImgYCoord+'px)');
  }

  var setBandMembersDescription = function() {
    $('.band-member-description').each(function(){
      var textHeight = $(this).find('.band-member-description-text').outerHeight();
      var bottomPosition = (textHeight + 20) * -1;

      $(this).css('bottom', bottomPosition);

    });
  };

  $(document).ready(function() {

    jumbotronAnimation();
    setBandMembersDescription();

    $(window).scroll(jumbotronAnimation);

    $('.gallery-slider-item a').colorbox({
        rel: '.gallery-slider-item',
        maxWidth: "100%",
        maxHeight: "100%",
        transition: "elastic",
        previous: '<i class="fas fa-chevron-left" aria-label="Previous photo"></i>',
        next: '<i class="fas fa-chevron-right" aria-label="Next photo"></i>',
        close: '<i class="fas fa-times" aria-label="Close gallery"></i>'
      });
  });

})(jQuery);